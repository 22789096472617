// @flow
import { gql } from '@apollo/client';
import { client } from 'infrastructure/apollo';
import { convertResultCalendarFromGraph } from 'services/Result';
import { type ResultCalendarContainerType } from 'types/Result';

const query = gql`
  query Rencontres($competitionId: [Int!], $structureId: ID) {
    Rencontres(
      competitionId: $competitionId
      Etat: { nom: ["Planifiée","En direct"] }
      Structure: { id: $structureId }
    ) {
      id
      dateOfficielle
      dateEffective
      Competition {
        id
        nom
        ClasseAge {
          code,
          Sexe { 
            code
          }
        }
        Famille { nom }
      }
      RencontreResultatLocale {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      RencontreResultatVisiteuse {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      Terrain {
        id
        nom
        Adresse {
          localite
          codePostal
          voie
          complement
          paysNom
          position {
            coordinates
          }
        }
      }
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
      Journee {
        id
        nom
      }
    }
  }
`;

export const fetchResultCalendarFromGraph = async (
  competitionId: number[],
  clubID: number
): Promise<ResultCalendarContainerType> => {
  if (clubID) {
    const response = await client.query({
      query,
      variables: { structureId: clubID, competitionId },
    });

    return convertResultCalendarFromGraph(response);
  }

  return {};
};

// @flow

import type { ApolloResponseType } from 'types/ApolloType';
import { nullRanking } from 'types/Ranking';
import { mergeIntoDefault } from 'utils/objectUtils';
import { getRankingLayout } from 'utils/graphResponseUtils';

export const convertRankingFromGraph = (rawGraphResponse: ApolloResponseType, clubID: string) => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return nullRanking;
  }
  const { competitions } = rawGraphResponse.data;
  return competitions.reduce((acc, { id: compID, nom: compNom, phases: rawPhase, saison }) => {
    const phases = rawPhase.map(({ id, nom, intraPoule: poule, nombrePoules }) => {
      const intraPoule = poule.reduce((acc, { id, classements: ranks }) => {
        const classements = ranks
          .sort((a, b) => a.position - b.position)
          .reduce((acc, {
            equipe: {
              nom,
              regroupement,
              structure
            },
            classement,
            position,
            }, index) => {
            if(regroupement) {
              if (regroupement.structures.filter((structure) => structure.id === clubID).length > 0) {
                acc.push(...getRankingLayout(ranks, index));
              }
            } else {
              if (structure.id === clubID) {
                acc.push(...getRankingLayout(ranks, index));
              }
            }
            return acc;
          }, []);
          if (classements.length) {
            acc = {
              id,
              classements,
            };
          }
          return acc;
        }, {});
      return intraPoule;
    });
    const rank = {
      id: parseInt(compID, 10),
      nom: compNom,
      classement: phases[0].classements,
      saison,
    };

    const comp = mergeIntoDefault(nullRanking, rank);
    acc.push(comp);

    return acc;
  }, []);
};

export const convertRankingFromRencontreGraph = (
  rawGraphResponse: ApolloResponseType,
  clubID: string,
  compID: string,
  compNom: string,
  saison: { nom: string, id: string },
) => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return nullRanking;
  }

  const { Phase: { intraPoule: poules } } = rawGraphResponse.data;

  const intraPoule = poules.reduce((acc, { id, classements: ranks }) => {
    const classements = ranks
      .sort((a, b) => a.position - b.position)
      .reduce((acc, {
        equipe: {
          nom,
          regroupement,
          structure
        },
        classement,
        position,
        }, index
      ) => {
        if(regroupement) {
          if (
            regroupement &&
            regroupement.structures &&
            Array.isArray(regroupement.structures) &&
            regroupement.structures.filter((structure) => structure.id === clubID).length > 0
          ) {
            acc.push(...getRankingLayout(ranks, index));
          }
        } else {
          if (structure.id === clubID) {
            acc.push(...getRankingLayout(ranks, index));
          }
        }
        return acc;
      }, []);

    if (classements.length) {
      acc = {
        id,
        classements,
      };
    }

    return acc;
  }, {});

  const rank = {
    id: parseInt(compID, 10),
    nom: compNom,
    classement: intraPoule.classements,
    saison,
  };

  const comp = mergeIntoDefault(nullRanking, rank);

  return [comp];
};

// @flow
import { gql } from '@apollo/client';
import { client, getClientForSeason } from 'infrastructure/apollo';
import { convertResultFromGraph } from 'services/Result';
import { convertStatsFromGraph } from 'services/TeamStats';
import { nullCompetitionResult } from 'types/Result';

const competitionsQuery = gql`
  query($structureId: Int, $saisonId: Int) {
    Structure(id: $structureId) {
      Competitions(saisonId: [$saisonId]) {
        id
      }
    }
  }
`;

const query = gql`
  query Rencontres($competitionId: [Int!], $structureId: ID) {
    Rencontres(
      competitionId: $competitionId
      Etat: { nom: ["Score validé", "Présaisie"] }
      Structure: { id: $structureId }
    ) {
      id
      dateOfficielle
      dateEffective
      Competition {
        id
        nom,
        ClasseAge {
          code,
          Sexe { 
            code
          }
        }
        Famille { nom }
        Saison {
          id
        }
      }
      RencontreResultatLocale {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      RencontreResultatVisiteuse {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
          StructuresItem {
            id
          }
        }
      }
      EquipeLocal {
        Personne {
          id
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom 
          embleme
          id
        }
        Regroupement {
          nom
          embleme 
          id
          StructuresItem {
            id
          }
        }
      }
      EquipeVisiteur {
        Personne {
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
      Journee {
        id
        nom
      }
    }
  }
`;

export const fetchResultFromGraph = async (competitionId: number[] | null, clubID: number, saisonId?: string) => {
  if (!clubID || (!competitionId && !saisonId)) {
    return nullCompetitionResult;
  }

  let ids = competitionId;
  let customClient = client;
  if (saisonId && !ids) {
    customClient = getClientForSeason(saisonId);

    const { data: { Structure: { Competitions } } } = await customClient.query({
      query: competitionsQuery,
      variables: { structureId: parseInt(clubID, 10), saisonId: parseInt(saisonId, 10) }
    });
    ids = Competitions.map(({ id }) => parseInt(id, 10));
  }

  const response = await client.query({
    query,
    variables: { structureId: clubID, competitionId: ids },
  });

  return {
    competitions: convertResultFromGraph(response),
    stats: convertStatsFromGraph(response, clubID)
  };
};

// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import EventApi from 'api/events/EventApi';
import {
  FETCH_EVENT_START,
  eventFetched,
  eventFetchError
} from 'actions/eventActions';
import type { FETCH_EVENT_START_ACTION } from 'actions/eventActions';
import { fetchEventNextMatchsFromGraph } from 'api/graphApi/EventNextMatchsApi';

import {
  FETCH_EVENTS_START,
  eventsFetched,
  eventsFetchError
} from 'actions/eventsActions';
import type {
  FETCH_EVENTS_START_ACTION,
} from 'actions/eventsActions';

export default function(apiExecutor: ApiExecutorType, staticApiExecutor: ApiExecutorType) {
  const eventsApi = new EventApi(apiExecutor);

  return function* eventsSaga(): GeneratorType {
    yield takeLatest(FETCH_EVENT_START, fetchEvent);
    yield takeLatest(FETCH_EVENTS_START, fetchEvents);
  };

  function* fetchEvents(action: FETCH_EVENTS_START_ACTION): Saga<void> {
    try {
      const { from, to, clubID, competitions } = action.payload;
      const events = yield call(eventsApi.fetchEvents, from, to);
      const nextMatchesEvents = yield call(
        fetchEventNextMatchsFromGraph,
        competitions.filter((compId) => !isNaN(parseInt(compId, 10)) && parseInt(compId, 10) > 0),
        parseInt(clubID, 10),
        from,
        to,
      );

      const allEvents = [...events, ...nextMatchesEvents]
        .sort((a, b) => a.startDate - b.startDate);

      yield put(eventsFetched(allEvents));
    } catch (e) {
      yield put(eventsFetchError());
    }
  }

  function* fetchEvent(action: FETCH_EVENT_START_ACTION): Saga<void> {
    try {
      const { slug } = action.payload;
      const events = yield call(eventsApi.fetchEvent, slug);
      yield put(eventFetched(events, true));
    } catch (e) {
      yield put(eventFetchError());
    }
  }
}

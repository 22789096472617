// @flow

/**
 * Only for debug env
 * Use idf as default api source
 * instead of host name
 */
export function getBaseUrl(): string {
  let source = 'api-clubs-develop.ffr2.nextmap.cloud/site-temoin';
  // source = 'api-clubs-develop.ffr2.nextmap.cloud/club-01';
  // source = 'api-clubs-develop.ffr2.nextmap.cloud/porc';

  // source = 'clubs-dev.local/club-01';
  // source = 'clubs-dev.local/porc';
  // source = 'clubs-dev.local/site-temoin';

  // source = 'api.club.ffr.fr/a-s-m-romagnat-rugby-feminin';
  // source = 'api.club.ffr.fr/apt-rugby';
  // source = 'api.club.ffr.fr/bazasrugby';
  // source = 'api.club.ffr.fr/cabrive-association';
  // source = 'api.club.ffr.fr/esmlrugby';
  // source = 'api.club.ffr.fr/esmonteux';
  // source = 'api.club.ffr.fr/grandchamp-rc';
  // source = 'api.club.ffr.fr/luzech';
  // source = 'api.club.ffr.fr/rcnoisylegrand';
  // source = 'api.club.ffr.fr/rcnebbiu';
  // source = 'api.club.ffr.fr/roc-giffois';
  // source = 'api.club.ffr.fr/scrc';
  // source = 'api.club.ffr.fr/tla-xv';
  // source = 'api.club.ffr.fr/rcmauguiocarnon';
  // source = 'api.clubs-dev.local/club-01';
  if (process.env.NODE_ENV === 'production') {
    let hostname = 'site-temoin.ffr.fr';
    if (typeof window !== 'undefined' && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }

    const clubId = hostname.split('.')[0];

    source = hostname
      .split('.')
      .splice(1)
      .join('.');

    if (source === 'ffr.nextmap.io' || source === 'ffr2.nextmap.cloud') {
      source = `api-clubs-develop.ffr2.nextmap.cloud/${clubId}`;
    } else {
      source = `api.club.ffr.fr/${clubId}`;
    }
  }

  return `https://${source}`;
}

export function getCSS() {
  let source = 'api-clubs-develop.ffr2.nextmap.cloud/wp-content/build/scss_library/site-temoin/styles_clubs.css';
  if (process.env.NODE_ENV === 'production') {
    let hostname = 'site-temoin.ffr.fr';
    if (typeof window !== 'undefined' && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }

    const clubId = hostname.split('.')[0];

    source = hostname
      .split('.')
      .splice(1)
      .join('.');

    if (source === 'ffr.nextmap.io' || clubId === 'site-temoin') {
      source = `api-clubs-develop.ffr2.nextmap.cloud/wp-content/build/scss_library/${clubId}/styles_clubs.css`;
    } else {
      source = `api.club.ffr.fr/wp-content/build/scss_library/${clubId}/styles_clubs.css`;
    }
  }

  // pour brancher le css du bo local
  // source = 'clubs-dev.local/wp-content/build/scss_library/porc/styles_clubs.css';

  return `https://${source}`;
}

export function getAgregateurUrl(): string {
  return 'https://api-agregateur.ffr.fr';
}

export const TIMEOUT = 60000;
export const WS_BASE_URL = getAgregateurUrl();
export const GRAPH_URL = getAgregateurUrl() + '/graphql';
export const BASE_URL = getBaseUrl() + '/wp-json';
export const MANIFEST_URL = '/manifest.json';
export const STATIC_BASE_URL = getBaseUrl() + '/static';
export const FORM_CONTACT_TOKEN = 'YXBpZmZyOjBSZnhOR0IleXdXSG5VM2xZZ0Q3N2M1Mg==';
export const COMPETITION_BASE_URL = 'https://competitions.ffr.fr/api';
export const COMPETITION_BASE_URL_FO = 'https://competitions.ffr.fr/';

// @flow
import React, { PureComponent } from 'react';

import { dynamicClassName } from 'utils/dynamicClassName';

import { MOBILE_SIZE } from 'constants/app';

import type { CardType } from 'types/PageList';

type Props = {
  hiddenMenuElements: CardType[],
  clubName: string,
  activeTeam: CardType,
  switchTeam: (team: string) => void,
  level?: string,
  classLevel?: string,
  withoutCategories?: boolean,
};

export type StateProps = {
  clubName: string,
};

type ComponentState = {
  isOpen: boolean
};

class TeamMenuDropdownMenu extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    isOpen: false
  };

  onClick = (e : MouseEvent) => {
    const { isOpen } = this.state;
    e.preventDefault();
    const element = document.getElementsByClassName('overlay');

    if (!isOpen) {
      for (var i = 0; i < element.length; i++) {
        element[i].classList.add('is-visible');
      }
    } else {
      for (var j = 0; j < element.length; j++) {
        element[j].classList.remove('is-visible');
      }
    }
    this.setState({
      isOpen: !isOpen
    });
  };

  renderHiddenMenuElements = () => {
    const { hiddenMenuElements, activeTeam } = this.props;

    return hiddenMenuElements
      .filter((team: CardType): boolean => team.object_slug !== activeTeam.object_slug)
      .map<any>((team: CardType, i: number): React$Element<'li'> => (
        <li key={team.object_id}>
          <a // eslint-disable-line
            title={`Se rendre à la page ${team.title}`}
            onClick={() => this._breakAll(team)}
            role="button"
            tabIndex={0}>
            {team.title}
          </a>
        </li>
      ));
  };

  renderListMenuElements = () => {
    const { activeTeam } = this.props;

    const ulClassName = (window.innerWidth < MOBILE_SIZE) ? 'is-collapsed' : '';
    return (
      <li>
        <span className="menu-team__category">{activeTeam && activeTeam.title}</span>
        <ul className={ulClassName}>
          {this.renderHiddenMenuElements()}
        </ul>
     </li>
    );
  };

  _breakAll = (team: CardType) => {
    const { switchTeam } = this.props;

    const element = document.querySelectorAll('.overlay');
    const firstLink = document.getElementsByClassName('accordion-trigger');
    const box = document.getElementById('boxTeam');

    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove('is-visible');
    }
    if (box) {
      box.classList.add('is-hidden');
    }
    for (let j = 0; j < firstLink.length; j++) {
      firstLink[j].classList.remove('is-selected');
    }

    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));

    switchTeam(team.object_slug);
  };

  _isOpenOverlay() {
    const { isOpen } = this.state;

    if (isOpen) {
      const element = document.querySelectorAll('.overlay');
      const firstLink = document.getElementsByClassName('accordion-trigger');
      const box = document.getElementById('boxTeam');

      for (let i = 0; i < element.length; i++) {
        element[i].onclick = () => {
          for (let i = 0; i < element.length; i++) {
            element[i].classList.remove('is-visible');
          }

          if (box) {
            box.classList.add('is-hidden');
          }

          for (let j = 0; j < firstLink.length; j++) {
            firstLink[j].classList.remove('is-selected');
          }
          this.setState((prevState) => ({
            isOpen: !prevState.isOpen
          }));
        };
      }
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      hiddenMenuElements,
      level,
      clubName,
      withoutCategories,
      classLevel,
      activeTeam: { title },
    } = this.props;

    const classNamesLink = dynamicClassName('accordion-trigger');
    isOpen && classNamesLink.add('is-selected ft-truncat');

    const classNamesDropdownMenu = dynamicClassName('accordion-panel has-overlay');
    !isOpen && classNamesDropdownMenu.add('is-hidden');
    const levelClass = classLevel ? classLevel : 'mb-3 ft-truncat';

    if (hiddenMenuElements.length > 0) {
      return (
        <div className="menu-team">
          {level && level === 'h1' &&
          <h1 className={levelClass}>
            {
              hiddenMenuElements.length === 1
                ? (title)
                : (
                  <a // eslint-disable-line
                    title="Voir les équipes"
                    role="button"
                    tabIndex="0"
                    onClick={this.onClick}
                    className={classNamesLink.build()}>
                    {`L'équipe ${title} de ${clubName}`}
                  </a>
                )
            }
          </h1>}
          {(!level || level !== 'h1') &&
          <h2 className={levelClass}>
            {
              hiddenMenuElements.length === 1
                ? (title)
                : (
                  <a // eslint-disable-line
                    title="Voir les équipes"
                    role="button"
                    tabIndex="0"
                    onClick={this.onClick}
                    className={classNamesLink.build()}
                  >
                    {title}
                  </a>
                )
            }
          </h2>}

          <ul id="boxTeam" className={classNamesDropdownMenu.build()}>
            {this._isOpenOverlay()}
            {!withoutCategories && this.renderListMenuElements()}
            {withoutCategories && this.renderHiddenMenuElements()}
          </ul>
        </div>
      );
    }
    return null;
  }
}

export default TeamMenuDropdownMenu;

// @flow

import type { LastResultType } from 'types/Ranking';

export type Personne = {
  id: string,
  nom: string,
  prenom: string,
  fonction: string,
};

export type ResultDetailType = {
  ourClub: boolean,
  id: string,
  essais: ?number,
  essaisDePenalite: ?number,
  butsApresEssai: ?number,
  butsPenalite: ?number,
  dropGoals: ?number,
  matchEffectifIncomplet: ?boolean,
  forfait: boolean,
  forfaitGeneral: boolean,
  meiMotif: ?string,
  cartonsRouges: ?number,
  tirsAuBut: ?number,
  pointsTerrains: ?number,
  regulationPoitsTerrain: ?number,
  pointsDeMarque: number,
  bonusOffensif: number,
  bonusDefensif: number,
  goalAverage: number,
};

export type TerrainType = {
  id: string,
  nom: string,
  address: string,
  localite: string,
  codePostal: string,
  voie: string,
  coordinates: number[],
};

export type StructureType = {
  id: string,
  equipeId: string,
  nom: string,
  embleme: string,
  structures?: StructureType[],
  isRegroupement: boolean,
  lastResult: LastResultType[],
};

export type ResultType = {
  id: string,
  dateOfficielle: Date,
  dateEffective: Date,
  etat: string,
  journeeId: string,
  journeeNom: string,
  arbitres: Personne[],
  competitionId: string,
  competitionNom: string,
  resultVisiteur: ResultDetailType,
  resultLocal: ResultDetailType,
  localStructure: StructureType,
  visiteurStructure: StructureType,
};

export type JourneeResultType = {
  id: string,
  nom: string,
  competitionId: string,
  competitionNom: string,
  date: Date,
  rencontres: ResultType[],
}

export type CompetitionResultType = {
  id: string,
  nom: string,
  journees: JourneeResultType[],
};

export type GenericCalendarType = {
  id: string,
  nom: string,
};
export type GenericCompetitionCalendarType = {
  id: string,
  nom: string,
  famille: string,
  age: string,
  sexe: string
};

export type ResultCalendarType = ResultType & {
  terrain: TerrainType,
  journee: GenericCalendarType,
  competition: GenericCompetitionCalendarType,
};

export type ResultCalendarContainerType = {
  [key: string]: ResultCalendarType[],
}

export const nullResultDetail: ResultDetailType = {
  id: '01',
  ourClub: false,
  essais: 0,
  essaisDePenalite: 0,
  butsApresEssai: 0,
  butsPenalite: 0,
  dropGoals: 0,
  matchEffectifIncomplet: false,
  forfait: false,
  forfaitGeneral: false,
  meiMotif: null,
  cartonsRouges: 0,
  tirsAuBut: 0,
  pointsTerrains: 0,
  regulationPoitsTerrain: null,
  pointsDeMarque: 0,
  bonusOffensif: 0,
  bonusDefensif: 0,
  goalAverage: 0,
};

export const nullStructure: StructureType = {
  id: '0',
  nom: 'Placeholder structure',
  embleme: 'https://api-agregateur.ffr.fr/embleme/club/club.svg',
  lastResult: [],
  isRegroupement: false,
  structures: [],
  equipeId: '0',
};

export const nullResult: ResultType = {
  id: '5',
  dateOfficielle: new Date(),
  dateEffective: new Date(),
  etat: 'Score validé',
  arbitres: [],
  journeeId: '18000',
  journeeNom: '',
  competitionId: '12000',
  competitionNom: '',
  resultVisiteur: nullResultDetail,
  resultLocal: nullResultDetail,
  localStructure: nullStructure,
  visiteurStructure: nullStructure,
};

export const nullJourneeResult: JourneeResultType = {
  id: '10',
  nom: 'Journée 1',
  competitionId: '',
  competitionNom: '',
  date: new Date(),
  rencontres: [nullResult],
};

export const nullCompetitionResult: CompetitionResultType = {
  id: '12',
  nom: 'Competition placeholder',
  journees: [nullJourneeResult],
};

export const nullGenericCalendar: GenericCalendarType = {
  id: '12',
  nom: 'Competition placeholder',
};

export const nullGenericCompetitionCalendar: GenericCompetitionCalendarType = {
  id: '12',
  nom: 'Competition placeholder',
  famille: 'Territoriale',
  age: '+18',
  sexe: 'F'
};

export const nullTerrain: TerrainType = {
  id: '120',
  nom: 'Terrain placeholder',
  address: '',
  localite: '',
  codePostal: '',
  voie: '',
  coordinates: [],
};

export const nullResultCalendar: ResultCalendarType = {
  ...nullResult,
  terrain: nullTerrain,
  journee: nullGenericCalendar,
  competition: nullGenericCompetitionCalendar,
};

export const nullResultCalendarContainer: ResultCalendarContainerType = {
  'Janvier 2020': [nullResultCalendar],
};

// @flow
import FooterBottom from 'components/footer/FooterBottom';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default connect((state: StateType) => ({
  footerElements: state.menuState.footer,
  footerCorporateElements: state.menuState.bottomNav,
  adresse: state.settingsState.adresse,
  banniere: state.settingsState.banniere,
  clubName: state.settingsState.structure,
  liens_rs: state.settingsState.liens_rs,
  logo: state.settingsState.logo,
  placeholder: state.settingsState.placeholder,
  telephone: state.settingsState.telephone,
  title: state.settingsState.title,
}))(FooterBottom);

// @flow

import type { RawMatchType } from 'types/GraphQL';

export type NextMatchType = {
  id: string,
  competition: {
    nom: string,
    nomFamille: string,
    classeAgeCode: string,
    sexeCode: string
  },
  localStructure: {
    id: string,
    code: string,
    embleme: string,
    nom: string
  },
  visiteurStructure: {
    id: string,
    code: string,
    embleme: string,
    nom: string
  },
  etat: ?string,
  journee: string,
  dateOfficielle: string,
  dateEffective: string
};

export type ListHomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BreadcrumbType = {
  name: string,
  to: string
};

export type HomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slugs: Object,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocMainPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string
};

export type BlocFaq = {
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  parent_slugs: string[],
  texte_cta: string
};

export type FaviconType = {
  'android-192': string,
  'android-256': string,
  'apple-touch-icon-152': string,
  'apple-touch-icon-180': string,
  'favicon-16': string,
  'favicon-32': string,
  'mstile-150': string
};

export type liensRsType = {
  fb_lien: string,
  instagram_lien: string,
  twitter_lien: string,
  youtube_lien: string,
  flickr_lien: string,
  linkedin_lien: string,
  twitch_lien: string,
  snapchat_lien: string,
  tik_tok_lien: string
};

export type TopNewsType = {
  label: string,
  link: string,
  title: string,
  featured_image: string
};

export type PartnerType = {
  cta: string,
  description: string,
  facebook: string,
  instagram: string,
  linkedin: string,
  lien: string,
  lien_type: string,
  logo: string,
  titre: string,
  twitter: string,
  youtube: string
};

export type CardMenuElementType = {
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  parent_slugs: string[],
  texte_cta: string
};

export type CompetitionEquipeType = {
  [key: string]: {
    equipe_slug: string,
    equipe_nom: string,
    competitions_ffr_slug: string
  }
};

export type SettingsType = {
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: []
    }
  },
  telephone: string,
  ligue: string,
  title: string,
  description: string,
  meta_title_home: string,
  meta_description_home: string,
  url: string,
  logo: string,
  saison_en_cours: number,
  liste_cards: ListHomeCardType[],
  titre_liste_cards: string,
  competitionsEquipes: CompetitionEquipeType,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailjet' | 'mailchimp',
    lien?: string,
    key?: string
  },
  cookies: number,
  top_news: TopNewsType,
  actualite_une: TopNewsType,
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  partenaires_une_cta: string,
  banniere: string,
  rejoignez_nous_background: string,
  club_id: string,
  structure_parente: {
    id: string,
    nom: string,
    code: string,
    siteInternet: string
  },
  rejoignez_nous: string,
  actualites_une_titre: string,
  actualites_une_description: string,
  equipes_une: CardMenuElementType[],
  equipes_une_titre: string,
  equipes_une_description: string,
  equipes_une_cta: string,
  equipes_une_lien: string,
  prochaine_rencontre: ?NextMatchType,
  prochaine_rencontre_lien: string,
  structure: string,
  mode_travaux: boolean,
  user_id: number,
  home_bloc_boutique: boolean,
  liste_blocs_un: CardMenuElementType[],
  liste_blocs_deux: CardMenuElementType[],
  titre_liste_blocs_un: string,
  titre_liste_blocs_deux: string,
  placeholder: string,
  actualite_ffr_vers_ecosysteme: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  cgus: string,
  cgu: number,
  google_verification: string,
  ville: string,
  acronyme: string,
  message_alerte: string
};

export type RawSettingsType = {
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: []
    }
  },
  telephone: string,
  ligue: string,
  title: string,
  description: string,
  meta_title_home: string,
  meta_description_home: string,
  url: string,
  email: string,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  saison_en_cours: number,
  titre_liste_cards: string,
  liste_cards: HomeCardType[],
  home_bloc_boutique: boolean,
  liste_blocs_un: CardMenuElementType[],
  liste_blocs_deux: CardMenuElementType[],
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: {
    type: false | 'campaign_monitor' | 'lien' | 'mailjet' | 'mailchimp',
    lien?: string,
    key?: string
  },
  cookies: number,
  top_news: TopNewsType,
  actualite_une: TopNewsType,
  partenaires: PartnerType[],
  partenaires_page: string,
  partenaires_une_titre: string,
  partenaires_une_description: string,
  partenaires_une_cta: string,
  banniere: string,
  rejoignez_nous_background: string,
  club_id: string,
  structure_parente: {
    id: string,
    nom: string,
    code: string,
    siteInternet: string
  },
  rejoignez_nous: string,
  actualites_une_titre: string,
  actualites_une_description: string,
  equipes_une: CardMenuElementType[],
  equipes_une_titre: string,
  equipes_une_description: string,
  equipes_une_cta: string,
  equipes_une_lien: string,
  prochaine_rencontre: RawMatchType,
  prochaine_rencontre_lien: string,
  structure: string,
  competitions_equipes: {
    [key: string]: {
      equipe_nom: string,
      equipe_slug: string,
      competitions_ffr_slug: string
    }
  },
  mode_travaux: boolean,
  user_id: number,
  titre_liste_blocs_un: string,
  titre_liste_blocs_deux: string,
  placeholder: string,
  actualite_ffr_vers_ecosysteme: {
    featured_media: Object,
    link: string,
    title: {
      rendered: string,
      raw: string
    },
    date: string,
    label: {
      name: string,
      slug: string
    }
  },
  cgus: string,
  cgu: number,
  google_verification: string,
  ville: string,
  acronyme: string,
  message_alerte: string
};

export const nullCardMenuElement: CardMenuElementType = {
  description: '',
  featured_image: {},
  object_id: 0,
  object_slug: '',
  parent_slugs: [],
  texte_cta: '',
  title: '',
  type_name: ''
};

export const nullHighlightedTeams: CardMenuElementType[] = [nullCardMenuElement];

export const nullRawNextMatch: RawMatchType = {
  Competition: {
    id: '',
    nom: '',
    nomCourt: '',
    Famille: {
      id: '',
      nom: ''
    },
    ClasseAge: {
      Sexe: {
        code: '',
        id: '',
        nom: ''
      },
      code: '',
      id: '',
      nom: ''
    }
  },
  CompetitionEquipeLocale: {
    id: '',
    ordre: 0,
    nom: '',
    Structure: {
      id: '',
      nom: '',
      code: '',
      embleme: ''
    },
    Regroupement: {
      id: '',
      nom: '',
      code: '',
      embleme: ''
    }
  },
  CompetitionEquipeVisiteuse: {
    id: '',
    ordre: 0,
    nom: '',
    Structure: {
      id: '',
      nom: '',
      code: '',
      embleme: ''
    },
    Regroupement: {
      id: '',
      nom: '',
      code: '',
      embleme: ''
    }
  },
  Etat: {
    id: '',
    nom: ''
  },
  Journee: {
    id: '',
    nom: '',
    numero: 0
  },
  RencontreResultatLocale: null,
  RencontreResultatVisiteuse: null,
  dateEffective: '',
  dateOfficielle: '',
  id: '',
  termine: null
};

export const nullNextMatch: NextMatchType = {
  id: '',
  competition: {
    nom: '',
    nomFamille: '',
    classeAgeCode: '',
    sexeCode: ''
  },
  localStructure: {
    id: '',
    code: '',
    embleme: '',
    nom: ''
  },
  visiteurStructure: {
    id: '',
    code: '',
    embleme: '',
    nom: ''
  },
  etat: '',
  journee: '',
  dateOfficielle: '',
  dateEffective: ''
};

export const nullPartner: PartnerType = {
  cta: '',
  description: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  lien: '',
  lien_type: '',
  logo: '',
  titre: '',
  twitter: '',
  youtube: ''
};

export const nullSettings: SettingsType = {
  adresse: {
    complement: '',
    localite: '',
    codePostal: '',
    voie: '',
    lieuDitBoitePostale: '',
    paysNom: '',
    immeuble: '',
    codeInsee: '',
    position: {
      type: '',
      coordinates: []
    }
  },
  telephone: '',
  ligue: '',
  title: '',
  competitionsEquipes: {},
  description: '',
  meta_title_home: '',
  meta_description_home: '',
  url: '',
  logo: '',
  saison_en_cours: 0,
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  titre_liste_cards: '',
  competitions_equipes: {},
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: '',
    flickr_lien: '',
    linkedin_lien: '',
    twitch_lien: '',
    snapchat_lien: '',
    tik_tok_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  top_news: {
    label: '',
    link: '',
    title: '',
    featured_image: ''
  },
  actualite_une: {
    label: '',
    link: '',
    title: '',
    featured_image: ''
  },
  partenaires: [nullPartner],
  partenaires_page: '',
  partenaires_une_titre: '',
  partenaires_une_description: '',
  partenaires_une_cta: '',
  banniere: '',
  rejoignez_nous_background: '',
  club_id: '',
  structure_parente: {
    id: '',
    nom: '',
    code: '',
    siteInternet: ''
  },
  rejoignez_nous: '',
  actualites_une_titre: '',
  actualites_une_description: '',
  equipes_une: nullHighlightedTeams,
  equipes_une_titre: '',
  equipes_une_description: '',
  equipes_une_cta: 'Voir toutes les équipes',
  equipes_une_lien: '',
  prochaine_rencontre: null,
  prochaine_rencontre_lien: '',
  structure: '',
  mode_travaux: false,
  user_id: 0,
  home_bloc_boutique: false,
  liste_blocs_un: [],
  liste_blocs_deux: [],
  titre_liste_blocs_un: '',
  titre_liste_blocs_deux: '',
  placeholder: '',
  actualite_ffr_vers_ecosysteme: {
    featured_media: {},
    link: '',
    title: {
      rendered: '',
      raw: ''
    },
    date: '',
    label: {
      name: '',
      slug: ''
    }
  },
  cgus: '',
  cgu: 0,
  google_verification: '',
  ville: '',
  acronyme: '',
  message_alerte: ''
};

// @flow

import type { RawClassicType, RawStructureType } from 'types/GraphQL';

type LastResultStructureType = {
  structure: RawStructureType,
  regroupement: RawStructureType,
  pointsTerrains: number,
};

export type LastResultType = {
  local: LastResultStructureType,
  visiteur: LastResultStructureType ,
};

export type RankingType = {
  nom: string,
  id: string,
  embleme: ?string,
  position: number,
  regulationPointsTerrain: ?number,
  pointTerrain: ?number,
  joues: ?number,
  gagnes: ?number,
  nuls: ?number,
  perdus: ?number,
  pointsDeMarqueAcquis: ?number,
  pointsDeMarqueConcedes: ?number,
  goalAverage: ?number,
  essaiMarques: ?number,
  essaiConcedes: ?number,
  bonusOffensif: ?number,
  bonusDefensif: ?number,
  lastResult: LastResultType[],
};

type RawClassementType = {
  embleme: ?string,
  position: number,
  regulationPointsTerrain: ?number,
  pointTerrain: ?number,
  joues: ?number,
  gagnes: ?number,
  nuls: ?number,
  perdus: ?number,
  pointsDeMarqueAcquis: ?number,
  pointsDeMarqueConcedes: ?number,
  goalAverage: ?number,
  essaiMarques: ?number,
  essaiConcedes: ?number,
  bonusOffensif: ?number,
  bonusDefensif: ?number,
};

export type RawRankingType = {
  classement: RawClassementType,
  equipe: RawClassicType & {
    lastResult: LastResultType[],
    regroupement: RawStructureType & {
      structures: RawStructureType,
    },
    structure: RawStructureType,
  },
  position: number,
};

export type CompetitionRankingType = {
  nom: string,
  id: number,
  classement: RankingType[],
  saison: RawClassicType,
};

export const nullLastResult: LastResultType = {
  local: {
    structure: {
      id: '01',
      nom: '',
      embleme: '',
      code: '',
    },
    regroupement: {
      id: '01',
      nom: '',
      embleme: '',
      code: '',
    },
    pointsTerrains: 0,
  },
  visiteur: {
    structure: {
      id: '01',
      nom: '',
      embleme: '',
      code: '',
    },
    regroupement: {
      id: '01',
      nom: '',
      embleme: '',
      code: '',
    },
    pointsTerrains: 0,
  }
};

export const nullClubRanking: RankingType = {
  nom: '',
  id: '',
  embleme: null,
  position: 0,
  regulationPointsTerrain: null,
  pointTerrain: null,
  joues: null,
  gagnes: null,
  nuls: null,
  perdus: null,
  pointsDeMarqueAcquis: null,
  pointsDeMarqueConcedes: null,
  goalAverage: null,
  essaiMarques: null,
  essaiConcedes: null,
  bonusOffensif: null,
  bonusDefensif: null,
  regroupement: null,
  lastResult: []
};

export const nullRanking: CompetitionRankingType = {
  nom: '',
  id: 0,
  classement: [],
  saison: {
    id: '',
    nom: '',
  },
};

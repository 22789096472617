// @flow

import type { ApolloResponseType } from 'types/ApolloType';
import { nullEventMatch, type EventMatchType } from 'types/Event';
import { mergeIntoDefault } from 'utils/objectUtils';

export const convertEventNextMatchsFromGraph = (rawGraphResponse: ApolloResponseType): EventMatchType[] => {
  if (!rawGraphResponse || !rawGraphResponse.data) {
    return [nullEventMatch];
  }

  const { Rencontres } = rawGraphResponse.data;

  const remappedMatches = Rencontres.reduce((matches, {
      id: matchID,
      Competition: { nom: compTag, id: compID },
      Journee: { nom: journee },
      CompetitionEquipeLocale: { nom: localNom, Structure: localStructure, Regroupement: localRegroupement },
      CompetitionEquipeVisiteuse: { nom: visiteurNom, Structure: visiteurStructure, Regroupement: visiteurRegroupement },
      Terrain,
      dateEffective: rawStartDate,
      Etat: {
        nom: etat,
      },
      etatMixte
    }) => {
      const { embleme: localEmbleme } = localRegroupement || localStructure;
      const { embleme: visiteurEmbleme } = visiteurStructure || visiteurRegroupement;
      const getAddress = () => {
        if (Terrain && Terrain.Adresse) {
          const {
            Adresse: {
              voie,
              codePostal,
              localite,
            }
          } = Terrain;
          return `${voie}, ${codePostal} ${localite}`;
        }
        return 'Adresse indisponible pour le moment';
      }; // @TODO : remove when it'll be sure to always have an address
      const address = getAddress();
        matches.push({
          matchID,
          compTag,
          compID,
          journee,
          localClub: localNom.replace(/\(.*\)/g, ''),
          localEmbleme,
          startDate: new Date(rawStartDate),
          visiteurClub: visiteurNom.replace(/\(.*\)/g, ''),
          visiteurEmbleme,
          address,
          etat: (etatMixte === 'En direct') ? 'En direct' : etat,
          categories: [{ name: 'match' }]
        });
        return matches;
    }, []);

  return remappedMatches.map((rawMatch) => mergeIntoDefault(nullEventMatch, rawMatch));
};

// @flow
import type { ApolloResponseType } from 'types/ApolloType';
import { type ResultDetailType, type StructureType, nullResultDetail, nullStructure } from 'types/Result';

import {
  type RawRencontreSimpleType,
  type RencontreSimpleType,
  type RencontreType,
  type RencontreLiveType,
  type RencontreArticleType,
  nullRencontre,
  nullRencontreArticle,
  // nullRencontreAction, @TODO : UNCOMMENT when plugged
  nullRencontreSimple
} from 'types/Rencontre';

import { getRanking } from 'utils/graphResponseUtils';
import { mergeIntoDefault } from 'utils/objectUtils';
import { formatedType } from 'utils/rencontreUtils';

export function convertActionFromWS(action: any) {
  const {
    minutes,
    type: rawType,
    sousType: rawSubType,
    periode,
    competitionEquipeId,
    Joueur1: joueur1,
    Joueur2: joueur2
  } = action;

  const { type, subType } = formatedType(rawType, rawSubType);

  const Joueur1 = (joueur1 && joueur1.Personne) || joueur1;
  const Joueur2 = (joueur2 && joueur2.Personne) || joueur2;

  return {
    id: `${minutes}_${periode}_${type}_${subType}`,
    minutes,
    periode,
    type,
    sousType: subType,
    equipe: { id: parseInt(competitionEquipeId, 10) },
    joueur:
      (Joueur1 &&
        Joueur1.nom &&
        Joueur1.prenom && {
          id: `${Joueur1.nom}_${Joueur1.prenom}`,
          nom: Joueur1.nom,
          prenom: Joueur1.prenom,
          displayName: `${Joueur1.nom} ${Joueur1.prenom[0]}.`
        }) ||
      null,
    joueur2:
      (Joueur2 &&
        Joueur2.nom &&
        Joueur2.prenom && {
          id: `${Joueur2.nom}_${Joueur2.prenom}`,
          nom: Joueur2.nom,
          prenom: Joueur2.prenom,
          displayName: `${Joueur2.nom} ${Joueur2.prenom[0]}.`
        }) ||
      null
  };
}

export function mergeActions(allActions: any, action: any, localId: any, visiteurId: any) {
  const { actions: nullActions } = nullRencontre;

  const formatedActions = [action];

  return nullActions.map<any>(({ type }) => {
    const localActions = formatedActions.filter(
      action => action.type === type && parseInt(action.equipe.id, 10) === parseInt(localId, 10)
    );

    const visiteurActions = formatedActions.filter(
      action => action.type === type && parseInt(action.equipe.id, 10) === parseInt(visiteurId, 10)
    );

    const globalActions = formatedActions.filter(action => action.type === type && type === 'debut_match');

    const allLocalActions = allActions.find(({ type: typeAll }) => type === typeAll);

    return {
      type,
      localActions: [...localActions, ...((allLocalActions && allLocalActions.localActions) || [])],
      visiteurActions: [...visiteurActions, ...((allLocalActions && allLocalActions.visiteurActions) || [])],
      globalActions: [...globalActions, ...((allLocalActions && allLocalActions.globalActions) || [])]
    };
  });
}

export function convertRencontreFromGraph(rawRencontre: ApolloResponseType, clubID: string): RencontreType {
  if (!rawRencontre || !rawRencontre.data.Rencontre) {
    return nullRencontre;
  }

  try {
    const {
      data: { Rencontre: rencontre }
    } = rawRencontre;
    const { actions: nullActions } = nullRencontre;
    const {
      Arbitres: rawArbitres,
      competitionId,
      Competition: {
        nom: competitionNom,
        classeAge: { id: classeAgeId }
      },
      Journee: { id: journeeId, nom: journeeNom },
      RencontreResultatVisiteuse: rawResultVisiteur,
      RencontreResultatLocale: rawResultLocal,
      CompetitionEquipeLocale: rawStructureLocal,
      CompetitionEquipeVisiteuse: rawStructureVisiteur,
      EquipeLocal,
      EquipeVisiteur,
      Etat: { nom: etat },
      etatMixte,
      id: rencontreId,
      dateOfficielle,
      dateEffective,
      Terrain,
      Actions: rawActions,
      competitionPhaseId,
      eligibleFDMD,
      forfait
    } = rencontre;

    const arbitres =
      rawArbitres
        .map(({ personne, fonction }) => {
          if (personne && personne.nom) {
            return {
              nom: personne.nom,
              prenom: personne.prenom,
              id: personne.id,
              fonction
            };
          }

          return null;
        })
        .filter(pers => pers !== null) || [];

    let address = '';
    let terrainNom = '';
    let terrainId = '';
    let position = '';
    let localite = '';

    if (Terrain) {
      const {
        Adresse: { codePostal, voie }
      } = Terrain || { Adresse: { codePostal: '', voie: '' } };

      ({
        id: terrainId,
        nom: terrainNom,
        position,
        Adresse: { localite }
      } = Terrain || { Adresse: { localite: '' }, position: {}, nom: '', id: '' });

      address = `${voie} ${codePostal} ${localite}`;
    }

    const localStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureLocal.Structure
        ? {
            ...rawStructureLocal.Structure,
            isRegroupement: false,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
        : {
            ...rawStructureLocal.Regroupement,
            isRegroupement: true,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
    );

    const visiteurStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureVisiteur.Structure
        ? {
            ...rawStructureVisiteur.Structure,
            isRegroupement: false,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
        : {
            ...rawStructureVisiteur.Regroupement,
            isRegroupement: true,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
    );

    const resultLocal: ResultDetailType = mergeIntoDefault(nullResultDetail, {
      ...rawResultLocal,
      ourClub: localStructure.id === clubID
    });
    const resultVisiteur: ResultDetailType = mergeIntoDefault(nullResultDetail, {
      ...rawResultVisiteur,
      ourClub: visiteurStructure.id === clubID
    });
    const allRawActions = rawActions.filter(action => {
      return action.Equipe !== null || action.type === 'KICKOFF';
    });
    const formatedActions = allRawActions.map(action => {
      const {
        id: actionId,
        minutes,
        type: rawType,
        sousType: rawSubType,
        periode,
        Equipe: rawEquipe,
        Joueur1,
        Joueur2
      } = action;

      const { type, subType } = formatedType(rawType, rawSubType);

      return {
        id: actionId,
        minutes,
        periode,
        type,
        sousType: subType,
        equipe: { id: rawEquipe ? rawEquipe.competitionEquipeId : 0 },
        joueur:
          Joueur1 && Joueur1.Personne
            ? {
                id: Joueur1.id,
                nom: Joueur1.Personne.nom,
                prenom: Joueur1.Personne.prenom,
                displayName: `${Joueur1.Personne.nom} ${Joueur1.Personne.prenom[0]}.`
              }
            : null,
        joueur2:
          Joueur2 && Joueur2.Personne
            ? {
                id: Joueur2.id,
                nom: Joueur2.Personne.nom,
                prenom: Joueur2.Personne.prenom,
                displayName: `${Joueur2.Personne.nom} ${Joueur2.Personne.prenom[0]}.`
              }
            : null
      };
    });

    const localJoueurs = EquipeLocal
      ? EquipeLocal.map(joueur => {
          const { Personne, ...rest } = joueur;

          return {
            ...rest,
            nom: Personne ? Personne.nom : '',
            prenom: Personne ? Personne.prenom : '',
            idPersonne: Personne ? Personne.id : ''
          };
        })
      : [];

    const visiteurJoueurs = EquipeVisiteur
      ? EquipeVisiteur.map(joueur => {
          const { Personne, ...rest } = joueur;

          return {
            ...rest,
            nom: Personne ? Personne.nom : '',
            prenom: Personne ? Personne.prenom : '',
            idPersonne: Personne ? Personne.id : ''
          };
        })
      : [];

    const actions = nullActions.map(({ type }) => {
      const localActions = formatedActions.filter(
        action => action.type === type && action.equipe.id === rawStructureLocal.id
      );

      const visiteurActions = formatedActions.filter(
        action => action.type === type && action.equipe.id === rawStructureVisiteur.id
      );

      const globalActions = formatedActions.filter(action => action.type === type && action.type === 'debut_match');

      return {
        type,
        localActions,
        visiteurActions,
        globalActions
      };
    });

    return mergeIntoDefault(nullRencontre, {
      id: rencontreId,
      competitionEquipeVisiteurId: rawStructureVisiteur.id,
      competitionEquipeLocaleId: rawStructureLocal.id,
      terrain: {
        id: terrainId,
        nom: terrainNom,
        localite,
        address: address.replace(/null/, '').trim(),
        coordinates: (position && position.coordinates) || null
      },
      classeAgeId,
      competitionPhaseId: competitionPhaseId.toString(),
      resultVisiteur,
      resultLocal,
      localStructure,
      visiteurStructure,
      etat: etatMixte === 'en direct' ? 'En direct' : etat,
      dateOfficielle: new Date(dateOfficielle),
      dateEffective: new Date(dateEffective),
      competitionId: `${competitionId}`,
      journeeId,
      arbitres,
      competition: {
        id: competitionId,
        nom: competitionNom
      },
      journee: {
        id: journeeId,
        nom: journeeNom
      },
      actions,
      localJoueurs,
      visiteurJoueurs,
      eligibleFDMD,
      forfait
    });
  } catch (error) {
    return nullRencontre;
  }
}

export function convertRencontreRankingFromGraph(rawRanking: ApolloResponseType, rencontre: any) {
  if (!rawRanking || !rawRanking.data.Phase) {
    return [];
  }

  try {
    const {
      data: {
        Phase: { intraPoule }
      }
    } = rawRanking;

    const { CompetitionEquipeLocale: rawStructureLocal, CompetitionEquipeVisiteuse: rawStructureVisiteur } = rencontre;

    const visiteurStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureVisiteur.Structure
        ? {
            ...rawStructureVisiteur.Structure,
            isRegroupement: false,
            nom: rawStructureVisiteur.nom,
            equipeId: rawStructureVisiteur.id
          }
        : {
            ...rawStructureVisiteur.Regroupement,
            isRegroupement: true,
            nom: rawStructureVisiteur.nom,
            equipeId: rawStructureVisiteur.id
          }
    );

    const localStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureLocal.Structure
        ? {
            ...rawStructureLocal.Structure,
            isRegroupement: false,
            nom: rawStructureLocal.nom,
            equipeId: rawStructureLocal.id
          }
        : {
            ...rawStructureLocal.Regroupement,
            isRegroupement: true,
            nom: rawStructureLocal.nom,
            equipeId: rawStructureLocal.id
          }
    );

    const poules = intraPoule.map(({ id, classements: ranks }) => {
      const classements = ranks
        .sort((a, b) => a.position - b.position)
        .filter(({ equipe: { id, regroupement, structure } }) => {
          if (id === localStructure.equipeId || visiteurStructure.equipeId === id) {
            return true;
          }
          return false;
        });

      if (classements.length) {
        return getRanking(classements);
      }

      return [];
    });

    const poule = poules.filter(poule => poule.length > 0);

    return poule ? poule[0] : [];
  } catch (error) {
    return [];
  }
}

export function convertRencontreToRencontreSimple(rawRencontre: RawRencontreSimpleType): ?RencontreSimpleType {
  if (!rawRencontre) {
    return null;
  }

  const {
    Competition: { nom: competitionNom },
    Journee: { nom: journeeNom },
    RencontreResultatVisiteuse: rawResultVisiteur,
    RencontreResultatLocale: rawResultLocal,
    CompetitionEquipeLocale: rawStructureLocal,
    CompetitionEquipeVisiteuse: rawStructureVisiteur,
    Etat: { nom: etat },
    etatMixte,
    id: rencontreId,
    dateEffective
  } = rawRencontre;

  const localStructure: StructureType = mergeIntoDefault(
    nullStructure,
    rawStructureLocal.Structure
      ? {
          ...rawStructureLocal.Structure,
          isRegroupement: false,
          nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
          equipeId: rawStructureLocal.id
        }
      : {
          ...rawStructureLocal.Regroupement,
          isRegroupement: true,
          nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
          equipeId: rawStructureLocal.id
        }
  );

  const visiteurStructure: StructureType = mergeIntoDefault(
    nullStructure,
    rawStructureVisiteur.Structure
      ? {
          ...rawStructureVisiteur.Structure,
          isRegroupement: false,
          nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
          equipeId: rawStructureVisiteur.id
        }
      : {
          ...rawStructureVisiteur.Regroupement,
          isRegroupement: true,
          nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
          equipeId: rawStructureVisiteur.id
        }
  );

  return mergeIntoDefault(nullRencontreSimple, {
    id: rencontreId,
    resultVisiteur: { pointsDeMarque: rawResultVisiteur.pointsDeMarque },
    resultLocal: { pointsDeMarque: rawResultLocal.pointsDeMarque },
    localStructure,
    visiteurStructure,
    etat: etatMixte === 'en direct' ? 'En direct' : etat,
    dateEffective: new Date(dateEffective),
    competition: {
      nom: competitionNom
    },
    journee: {
      nom: journeeNom
    }
  });
}

export function convertRencontreArticle(rawRencontre: ApolloResponseType): RencontreArticleType {
  if (!rawRencontre || !rawRencontre.data.Rencontre) {
    return nullRencontreArticle;
  }

  try {
    const {
      data: { Rencontre: rencontre }
    } = rawRencontre;

    const {
      id,
      Competition,
      Journee: { nom: journee },
      dateEffective,
      dateOfficielle,
      CompetitionEquipeLocale: rawStructureLocal,
      CompetitionEquipeVisiteuse: rawStructureVisiteur,
      Etat: { nom: etat },
      etatMixte,
      forfait
    } = rencontre;

    const localStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureLocal.Structure
        ? {
            ...rawStructureLocal.Structure,
            isRegroupement: false,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
        : {
            ...rawStructureLocal.Regroupement,
            isRegroupement: true,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
    );

    const visiteurStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureVisiteur.Structure
        ? {
            ...rawStructureVisiteur.Structure,
            isRegroupement: false,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
        : {
            ...rawStructureVisiteur.Regroupement,
            isRegroupement: true,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
    );

    let pointsStructureVisiteur = 0;
    if (rencontre.RencontreResultatVisiteuse && rencontre.RencontreResultatVisiteuse.pointsDeMarque) {
      pointsStructureVisiteur = rencontre.RencontreResultatVisiteuse.pointsDeMarque;
    }
    let pointsStructureLocale = 0;
    if (rencontre.RencontreResultatLocale && rencontre.RencontreResultatLocale.pointsDeMarque) {
      pointsStructureLocale = rencontre.RencontreResultatLocale.pointsDeMarque;
    }

    const competition = {
      nom: Competition.nom,
      nomFamille: Competition.Famille.nom,
      classeAgeCode: Competition.ClasseAge.code,
      sexeCode: Competition.ClasseAge.Sexe.code
    };

    return mergeIntoDefault(nullRencontreArticle, {
      id,
      localStructure,
      visiteurStructure,
      pointsStructureVisiteur,
      pointsStructureLocale,
      competition,
      etat: etatMixte === 'en direct' ? 'En direct' : etat,
      journee,
      dateEffective: new Date(dateEffective),
      dateOfficielle: new Date(dateOfficielle),
      forfait
    });
  } catch (error) {
    return nullRencontreArticle;
  }
}

export function convertRencontreArticleCategory(rencontre: RawRencontreSimpleType): ?RencontreArticleType {
  try {
    const {
      id,
      Competition,
      Journee: { nom: journee },
      dateEffective,
      CompetitionEquipeLocale: rawStructureLocal,
      CompetitionEquipeVisiteuse: rawStructureVisiteur,
      Etat: { nom: etat },
      etatMixte
    } = rencontre;

    let pointsStructureVisiteur = 0;
    if (rencontre.RencontreResultatVisiteuse && rencontre.RencontreResultatVisiteuse.pointsDeMarque) {
      pointsStructureVisiteur = rencontre.RencontreResultatVisiteuse.pointsDeMarque;
    }
    let pointsStructureLocale = 0;
    if (rencontre.RencontreResultatLocale && rencontre.RencontreResultatLocale.pointsDeMarque) {
      pointsStructureLocale = rencontre.RencontreResultatLocale.pointsDeMarque;
    }

    const localStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureLocal.Structure
        ? {
            ...rawStructureLocal.Structure,
            isRegroupement: false,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
        : {
            ...rawStructureLocal.Regroupement,
            isRegroupement: true,
            nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureLocal.id
          }
    );

    const visiteurStructure: StructureType = mergeIntoDefault(
      nullStructure,
      rawStructureVisiteur.Structure
        ? {
            ...rawStructureVisiteur.Structure,
            isRegroupement: false,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
        : {
            ...rawStructureVisiteur.Regroupement,
            isRegroupement: true,
            nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
            equipeId: rawStructureVisiteur.id
          }
    );

    const competition = {
      nom: Competition.nom,
      nomFamille: Competition.Famille.nom,
      classeAgeCode: Competition.ClasseAge.code,
      sexeCode: Competition.ClasseAge.Sexe.code
    };

    return mergeIntoDefault(nullRencontreArticle, {
      id,
      localStructure,
      visiteurStructure,
      pointsStructureVisiteur,
      pointsStructureLocale,
      competition,
      etat: etatMixte === 'en direct' ? 'En direct' : etat,
      journee,
      dateEffective: new Date(dateEffective)
    });
  } catch (error) {
    return null;
  }
}

export function convertRencontreLiveFullGraphToRencontreLive(
  response: ApolloResponseType
): { [key: string]: RencontreLiveType } {
  if (
    !response.data ||
    (response.data &&
      (!response.data.Rencontres || (response.data.Rencontres && response.data.Rencontres.length === 0)))
  ) {
    return {};
  }

  try {
    const { Rencontres: rencontres } = response.data;
    const { actions: nullActions } = nullRencontre;

    return rencontres.reduce((acc, rencontre) => {
      const {
        Competition: {
          id: competitionId,
          nom: competitionNom,
          Famille: { nom: nomFamille },
          classeAge: {
            id: classeAgeId,
            code: classeAgeCode,
            Sexe: { code: sexeCode }
          }
        },
        CompetitionEquipeLocale: rawStructureLocal,
        CompetitionEquipeVisiteuse: rawStructureVisiteur,
        Etat: { nom: etat },
        etatMixte,
        id: rencontreId,
        Actions: rawActions
      } = rencontre;

      const localStructure: StructureType = mergeIntoDefault(
        nullStructure,
        rawStructureLocal.Structure
          ? {
              ...rawStructureLocal.Structure,
              isRegroupement: false,
              nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
              equipeId: rawStructureLocal.id
            }
          : {
              ...rawStructureLocal.Regroupement,
              isRegroupement: true,
              nom: rawStructureLocal.nom.replace(/\(.*\)/g, ''),
              equipeId: rawStructureLocal.id
            }
      );

      const visiteurStructure: StructureType = mergeIntoDefault(
        nullStructure,
        rawStructureVisiteur.Structure
          ? {
              ...rawStructureVisiteur.Structure,
              isRegroupement: false,
              nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
              equipeId: rawStructureVisiteur.id
            }
          : {
              ...rawStructureVisiteur.Regroupement,
              isRegroupement: true,
              nom: rawStructureVisiteur.nom.replace(/\(.*\)/g, ''),
              equipeId: rawStructureVisiteur.id
            }
      );

      const formatedActions = rawActions.map(action => {
        const {
          id: actionId,
          minutes,
          type: rawType,
          sousType: rawSubType,
          periode,
          Equipe: rawEquipe,
          Joueur1,
          Joueur2
        } = action;

        const { type, subType } = formatedType(rawType, rawSubType);

        return {
          id: actionId,
          minutes,
          periode,
          type,
          sousType: subType,
          equipe: { id: rawEquipe ? rawEquipe.competitionEquipeId : 0 },
          joueur:
            Joueur1 && Joueur1.Personne
              ? {
                  id: Joueur1.id,
                  nom: Joueur1.Personne.nom,
                  prenom: Joueur1.Personne.prenom,
                  displayName: `${Joueur1.Personne.nom} ${Joueur1.Personne.prenom[0]}.`
                }
              : null,
          joueur2:
            Joueur2 && Joueur2.Personne
              ? {
                  id: Joueur2.id,
                  nom: Joueur2.Personne.nom,
                  prenom: Joueur2.Personne.prenom,
                  displayName: `${Joueur2.Personne.nom} ${Joueur2.Personne.prenom[0]}.`
                }
              : null
        };
      });

      const actions = nullActions.map(({ type }) => {
        const localActions = formatedActions.filter(
          action => action.type === type && action.equipe.id === rawStructureLocal.id
        );

        const visiteurActions = formatedActions.filter(
          action => action.type === type && action.equipe.id === rawStructureVisiteur.id
        );

        const globalActions = formatedActions.filter(action => action.type === 'debut_match' && type === 'debut_match');
        return {
          type,
          localActions,
          visiteurActions,
          globalActions
        };
      });

      acc[String(rencontreId)] = {
        id: rencontreId,
        competitionEquipeVisiteurId: rawStructureVisiteur.id,
        competitionEquipeLocaleId: rawStructureLocal.id,
        classeAgeId,
        localStructure,
        visiteurStructure,
        etat: etatMixte === 'en direct' ? 'En direct' : etat,
        competition: {
          id: competitionId,
          nom: competitionNom,
          nomFamille,
          classeAgeCode,
          sexeCode
        },
        actions
      };

      return acc;
    }, {});
  } catch (error) {
    return {};
  }
}

// @flow
import { gql } from '@apollo/client';
import { client } from 'infrastructure/apollo';
import { convertEventNextMatchsFromGraph } from 'services/EventNextMatchs';
import { type EventMatchType } from 'types/Event';

const query = gql`
  query Rencontres($competitionId: [Int!], $clubID: ID, $dateDebut: Date, $dateFin: Date) {
    Rencontres(
      competitionId: $competitionId
      dateDebut: $dateDebut,
      dateFin: $dateFin,
      Structure: { id: $clubID }
    ) {
      id
      dateOfficielle
      dateEffective
      Competition {
        id
        nom
      }
      RencontreResultatLocale {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      RencontreResultatVisiteuse {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage,
      }
      Terrain {
        id
        nom
        Adresse {
          localite
          codePostal
          voie
          complement
          paysNom
          position {
            coordinates
          }
        }
      }
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
      Journee {
        id
        nom
      }
    }
  }
`;

export const fetchEventNextMatchsFromGraph = async (
  competitionId: number[],
  clubID: number,
  dateDebut: Date,
  dateFin: Date
): Promise<EventMatchType[]> => {
  if (clubID) {
    const response = await client.query({
      query,
      variables: {
        clubID,
        competitionId,
        dateDebut,
        dateFin,
      },
    });

    return convertEventNextMatchsFromGraph(response);
  }

  return [];
};

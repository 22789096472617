// @flow
import { gql } from '@apollo/client';
import { client } from 'infrastructure/apollo';
import { convertRankingFromRencontreGraph } from 'services/Ranking';
import {
  convertRencontreFromGraph,
  convertRencontreRankingFromGraph,
  convertRencontreToRencontreSimple,
  convertRencontreArticle,
  convertRencontreLiveFullGraphToRencontreLive
} from 'services/Rencontre';
import { queryPhase } from './RankingApi';
import type { RankingType } from 'types/Ranking';
import type { RencontreType, RencontreArticleType, RencontreLiveType } from 'types/Rencontre';

const query = gql`
  query Rencontre($id: Int) {
    Rencontre(id: $id) {
      competitionId
      forfait
      Journee {
        id
        nom
      }
      Competition {
        nom
        code
        nomCourt
        saison: Saison {
          id
          nom
        }
        classeAge: ClasseAge {
          id
        }
      }
      Arbitres {
        id
        fonction
        personne: Personne {
          id
          nom
          prenom
        }
      }
      competitionPhaseId
      Actions {
        id
        minutes
        periode
        type
        sousType
        Equipe {
          competitionEquipeId: id
        }
        Joueur1 {
          id
          Personne {
            nom
            prenom
          }
        }
        Joueur2 {
          id
          Personne {
            nom
            prenom
          }
        }
      }
      EquipeLocal {
        id
        grade
        estCapitaine
        estD
        estRemplacant
        estPremiereLigne
        estG
        estStarter
        estT
        numero
        position
        Personne {
          id
          nom
          prenom
        }
      }
      EquipeVisiteur {
        id
        grade
        estCapitaine
        estD
        estRemplacant
        estPremiereLigne
        estG
        estStarter
        estT
        numero
        position
        Personne {
          id
          nom
          prenom
        }
      }
      RencontreResultatLocale {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage
      }
      Terrain {
        id
        nom
        Adresse {
          localite
          codePostal
          voie
          immeuble
          complement
          paysNom
          position {
            type
            coordinates
          }
        }
      }
      RencontreResultatVisiteuse {
        id
        essais
        essaisDePenalite
        butsApresEssai
        butsPenalite
        dropGoals
        matchEffectifIncomplet
        forfait
        forfaitGeneral
        meiMotif
        cartonsRouges
        tirsAuBut
        pointsTerrains
        regulationPoitsTerrain
        pointsDeMarque
        bonusOffensif
        bonusDefensif
        goalAverage
      }
      id
      dateOfficielle
      dateEffective
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
          structures: StructuresItem {
            id
            nom
            embleme
          }
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
          structures: StructuresItem {
            id
            nom
            embleme
          }
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
      Journee {
        id
        nom
      }
      eligibleFDMD
    }
  }
`;
const rencontreArticleQuery = gql`
  query Rencontre($id: Int) {
    Rencontre(id: $id) {
      forfait
      dateOfficielle
      dateEffective
      competitionId
      Competition {
        id
        nom
        Famille {
          nom
        }
        ClasseAge {
          code
          Sexe {
            code
          }
        }
      }
      Journee {
        id
        nom
      }
      RencontreResultatLocale {
        pointsDeMarque
      }
      RencontreResultatVisiteuse {
        pointsDeMarque
      }
      id
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
    }
  }
`;

const liveMeetings = gql`
  query Structure($id: Int) {
    Structure(id: $id) {
      rencontreIds: RencontresEnCours {
        id
      }
    }
  }
`;

const liveMeetingsFull = gql`
  query Rencontres($ids: [Int]) {
    Rencontres(id: $ids) {
      id
      Competition {
        id
        nom
        code
        Famille {
          nom
        }
        classeAge: ClasseAge {
          id
          code
          Sexe {
            code
          }
        }
      }
      Actions {
        id
        minutes
        periode
        type
        sousType
        Equipe {
          competitionEquipeId: id
        }
        Joueur1 {
          id
          Personne {
            nom
            prenom
          }
        }
        Joueur2 {
          id
          Personne {
            nom
            prenom
          }
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
    }
  }
`;
const previousMeetingsQuery = gql`
  query Rencontres($Regroupement: [RegroupementInput]!, $Structure: [StructureInput]!, $classeAgeId: [Int]) {
    Rencontres(
      Etat: { id: [5] }
      Regroupement: $Regroupement
      Structure: $Structure
      exclusive: true
      pageSize: 3
      classeAgeId: $classeAgeId
      order: "reverse:dateEffective"
    ) {
      RencontreResultatLocale {
        pointsDeMarque
      }
      RencontreResultatVisiteuse {
        pointsDeMarque
      }
      Competition {
        nom
        code
        nomCourt
        CompetitionNom {
          nom
        }
      }
      id
      dateOfficielle
      dateEffective
      CompetitionEquipeLocale {
        id
        nom
        Structure {
          id
          nom
          embleme
        }
        Regroupement {
          id
          nom
          embleme
        }
      }
      CompetitionEquipeVisiteuse {
        id
        nom
        Structure {
          nom
          embleme
          id
        }
        Regroupement {
          nom
          embleme
          id
        }
      }
      Etat {
        id
        nom
      }
      etatMixte
      Journee {
        id
        nom
      }
    }
  }
`;

export const fetchLastRencontresFromGraph = async (
  regroupement: { id: string }[],
  structure: { id: string }[],
  classeAgeId: number[]
) => {
  const response = await client.query({
    query: previousMeetingsQuery,
    variables: {
      Structure: structure,
      Regroupement: regroupement,
      classeAgeId
    }
  });

  return response.data.Rencontres.map(convertRencontreToRencontreSimple);
};

export const fetchRencontreFromGraph = async (
  id: number,
  clubID: string
): Promise<{ rencontre: RencontreType, ranking: RankingType[] }> => {
  const response = await client.query({
    query,
    variables: { id }
  });

  const rencontre = convertRencontreFromGraph(response, clubID);

  const responseRanking = await client.query({
    query: queryPhase,
    variables: {
      id: parseInt(rencontre.competitionPhaseId, 10),
      structures: [parseInt(clubID, 10)]
    }
  });

  const ranking = convertRencontreRankingFromGraph(responseRanking, response.data.Rencontre);
  const competition = (response.data && response.data.Rencontre && response.data.Rencontre.Competition) || {};

  const rankingCompetition = convertRankingFromRencontreGraph(
    responseRanking,
    clubID,
    (competition && competition.id) || '',
    (competition && competition.nom) || '',
    (competition && competition.saison) || { nom: '2019-2020', id: '22' }
  );
  return { rencontre, ranking, rankingCompetition };
};

export const fetchRencontreArticleFromGraph = async (id: number): Promise<RencontreArticleType> => {
  const query = rencontreArticleQuery;
  const response = await client.query({
    query,
    variables: { id }
  });

  return convertRencontreArticle(response);
};

export const fetchLiveRencontresFromGraph = async (id: number) => {
  const response = await client.query({
    query: liveMeetings,
    variables: { id }
  });

  return (
    (response.data &&
      response.data.Structure &&
      Array.isArray(response.data.Structure.rencontreIds) &&
      response.data.Structure.rencontreIds.map(rencontre => rencontre.id)) ||
    []
  );
};

export const fetchRencontreLiveFull = async (ids: number[]): { [key: string]: RencontreLiveType } => {
  const response = await client.query({
    query: liveMeetingsFull,
    variables: { ids: ids.map(id => parseInt(id, 10)) }
  });

  return convertRencontreLiveFullGraphToRencontreLive(response);
};

// @flow
import React, { PureComponent } from 'react';

import { withHelmet } from 'infrastructure/seo/Helmet';
import type { MenuElementType } from 'types/MenuElement';
import type { liensRsType } from 'types/Settings';
import { Link } from 'react-router-dom';
import FooterSelect from 'components/footer/FooterSelect';
import { getBaseUrl } from 'constants/api';

import './FooterBottomStyle.css';

type Props = {
  footerElements: MenuElementType[],
  footerCorporateElements: MenuElementType[],
  adresse: {
    complement: string,
    localite: string,
    codePostal: string,
    voie: string,
    lieuDitBoitePostale: string,
    paysNom: string,
    immeuble: string,
    codeInsee: string,
    position: {
      type: string,
      coordinates: []
    },
  },
  banniere: string,
  clubName: string,
  logo: string,
  liens_rs: liensRsType,
  placeholder: string,
  telephone: string,
  title: string,
};

class FooterBottom extends PureComponent<Props> {
  static defaultProps = {
    footerElements: []
  };

  renderFooterCategory = () => {
    const { footerElements } = this.props;

    const categories = [];
    let categoriesCount = 0;
    footerElements.forEach((footerCategory, index) => {
      categories[categoriesCount] = categories[categoriesCount] || [];
      categories[categoriesCount].push(
        <FooterSelect key={index} footerCategory={footerCategory} />
      );
      if ((index + 1) % 4 === 0) {
        // the 4 here is how many items you want per row
        // if we can perfectly divide the current position by 4
        // we have filled a row, so now increment the row
        categoriesCount++;
      }
    });
    return categories;
  };

  renderCorporateLinks = () => {
    const { footerCorporateElements } = this.props;
    return footerCorporateElements && footerCorporateElements.map(({ title, slug_complet, object_slug, url, target }) => (
      <li key={`corporate_${url}`}>
        {slug_complet && 
        <Link
          to={slug_complet}
          title={title}>
            {title}
        </Link>}
        {!slug_complet && 
        <a
          href={url}
          target={target}
          title={title}>
          {title}
        </a>}
      </li>
    ));
  };

  render() {
    const {
      adresse: { voie, lieuDitBoitePostale, complement, codePostal, localite },
      telephone,
      logo,
      title
    } = this.props;

    const categories = this.renderFooterCategory();

    const capitalizedVoie = `${voie ? voie.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedLieuDitBoitePostale = `${lieuDitBoitePostale ? lieuDitBoitePostale.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedComplement = `${complement ? complement.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedLocalite = `${localite ? localite.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;

    const internationalPhoneNumber = (telephone.slice(0, 1) === "0") ? `+33${telephone.substr(1)}` : telephone;

    return (
      <>
        <div className="container footer__links">
          <div className="footer__contact-detail">              
              <div className="footer__address">
                <i className="icon icon-place"></i>
                <div className="footer__address-detail">
                  <p>{capitalizedVoie}</p>
                  <p>{capitalizedLieuDitBoitePostale}</p>
                  <p>{capitalizedComplement}</p>
                  <p>{codePostal} {capitalizedLocalite}</p>
                </div>
              </div>
              {
                internationalPhoneNumber && (
                  <div className="footer__phone-number">
                    <i className="icon icon-phone"></i>
                    <p>{internationalPhoneNumber}</p>
                  </div>
                )
              }
            </div>
          { categories.map((ar, i) => <div key={i} className="row"> { ar } </div>) }
          <div
            className="footer__logo mt-3 mt-lg-0"
          >
            <span className='badge-score'>
              <img src={logo} alt={`Logo officiel de la ${title}`} />
            </span>
          </div>
        </div>
        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  {this.renderCorporateLinks()}
                  {<li><a href={getBaseUrl()+'/wp-admin/'} rel="nofollow">Administration</a></li>}
                </ul>
              </div>
              <div className="col col-lg-4">
                <p className="footer__corporate-legal">© 2019-{new Date().getFullYear()} {title}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withHelmet(FooterBottom)((Helmet, pageProps) => {
    const {
      adresse: {
        voie,
        lieuDitBoitePostale,
        complement,
        codePostal,
        localite,
        position
      },
      banniere,
      clubName,
      liens_rs,
      logo,
      placeholder,
      telephone
    } = pageProps;

    const sameAs = Object.keys(liens_rs).map((key) => {
      return liens_rs[key];
    }).filter((value) => {
      return typeof value === 'string' && value !== '';
    }).map((link) => {
      return typeof link === 'string' && (`"${link}"`);
    }).join(', ');

    const capitalizedVoie = `${voie ? voie.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedLieuDitBoitePostale = `${lieuDitBoitePostale ? lieuDitBoitePostale.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedComplement = `${complement ? complement.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;
    const capitalizedLocalite = `${localite ? localite.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}`;

    const internationalPhoneNumber = (telephone.slice(0, 1) === "0") ? `+33${telephone.substr(1)}` : telephone;

  return (
    <Helmet>
      <link rel={'canonical'} href={window.location.href} />
      <script type="application/ld+json">
        {
          `[
            {
              "@context": "https://schema.org",
              "@type": "SportsActivityLocation",
              "name": "${clubName || 'Mon site club - FFR'}",
              "url": "https://${window.location.host}",
              "image": "${placeholder ?? banniere}",
              "logo": "${logo}",
              "sameAs": [${sameAs}],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "${capitalizedVoie ?? ''} ${capitalizedLieuDitBoitePostale ?? ''} ${capitalizedComplement ?? ''}",
                "addressLocality": "${capitalizedLocalite ?? ''}",
                "postalCode": "${codePostal ?? ''}",
                "addressCountry": "FR"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${position && position.coordinates && position.coordinates[1] ? position.coordinates[1] : '' },
                "longitude": ${position && position.coordinates && position.coordinates[0] ? position.coordinates[0] : '' }
              },
              "telephone": "${internationalPhoneNumber}"
            },  
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://${window.location.host}",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://${window.location.host}/recherche?s={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          ]`
        }
      </script>
    </Helmet>
  );
});

          